jQuery(document).ready(function () {
  $('nav#menu').mmenu({
    drag: true,
    pageScroll: {
      scroll: true,
      update: true
    },
    'extensions': [
      'theme-dark',
      'fx-menu-fade',
      'fx-listitems-slide'
    ]
  }, {
    classNames: {
      fixedElements: {
        fixed: 'fix',
        sticky: 'sticky-top'
      }
    }
  })
  $('#pageHeader').sticky({topSpacing: 0})
  $('#pageHeader').on('sticky-end', function () { $(this).parent('.sticky-wrapper').css({height: 'auto'}) }) // sticky 補丁

  $().UItoTop({
    easingType: 'easeOutQuart',
    text: ''
  })
  //-$('.dropdown-hover').dropdownHover(100)

  $('#heroBanner').slick({
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 600,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })

  $('.card-slider-wrapper').slick({
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      }
    ]
  })

  $('.slider-teacher').slick({
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      }
    ]
  })

  $('#twzipcode').twzipcode();

  // 判斷發票類型
  const invoiceRadio = document.checkoutForm !== undefined ? document.checkoutForm.invoiceType : null,
        einvoiceField = document.querySelector('#einvoice-field'),
        donateField = document.querySelector('#donate-field'),
        GUIField = document.querySelector('#GUI-field')
  function resetInvoiceTypeField(params) {
    if (!einvoiceField.classList.contains('d-none')) {
      einvoiceField.classList.add('d-none')
    }
    if (!donateField.classList.contains('d-none')) {
      donateField.classList.add('d-none')
    }
    if (!GUIField.classList.contains('d-none')) {
      GUIField.classList.add('d-none')
    }
  }
  if (document.checkoutForm !== undefined) {
    for (let i = 0; i < invoiceRadio.length; i++) {
      invoiceRadio[i].addEventListener('change', function() {
          console.log(this.value)
          resetInvoiceTypeField();
          switch (this.value) {
            case 'einvoice':
              einvoiceField.classList.remove('d-none')
              break;
            case 'donate':
              donateField.classList.remove('d-none')
              break;
            case 'gui':
              GUIField.classList.remove('d-none')
              break;
            default:
              break;
          }
      });
    }
  }

  //判斷電子發票載具類型
  const eInvoiceRadio = document.checkoutForm !== undefined ? document.checkoutForm.einvoice : null,
        einvo_phone = document.querySelector('#einvoice-phone-field'),
        einvo_moica = document.querySelector('#einvoice-moica-field');
  if (document.checkoutForm !== undefined) {
    for (let i = 0; i < eInvoiceRadio.length; i++) {
      eInvoiceRadio[i].addEventListener('change', function() {
          console.log(this.value)
          if (!einvo_phone.classList.contains('d-none')) {
            einvo_phone.classList.add('d-none')
          }
          if (!einvo_moica.classList.contains('d-none')) {
            einvo_moica.classList.add('d-none')
          }
          switch (this.value) {
            case 'member':
              break;
            case 'phone':
              einvo_phone.classList.remove('d-none')
              break;
            case 'moica':
              einvo_moica.classList.remove('d-none')
              break;
            default:
              break;
          }
      });
    }
  }
  // 課程評分
  $(".my-rating").starRating({
    totalStars: 5,
    emptyColor: 'lightgray',
    hoverColor: 'orange',
    activeColor: 'gold',
    ratedColor: '#E60A13',
    initialRating: 2,
    strokeWidth: 1,
    useGradient: false,
    disableAfterRate: false,

    minRating: 1,
    onHover: function(currentIndex, currentRating, $el){
      $('.live-rating').text(currentIndex);
    },
    onLeave: function(currentIndex, currentRating, $el){
      $('.live-rating').text(currentRating);
    },
    callback: function(currentRating, $el){
      // alert('rated ' + currentRating);
      // console.log('DOM element ', $el);
    }
  });


});

function toggleModalMenu(e) {
  e.preventDefault();
  let target = e.target.dataset.target
  const modal = document.querySelector(target)
  modal.classList.add('open-chapter')
  document.body.classList.add('modalChapter-open')
}
//關閉影片選單
function modalClsoe(e) {
  e.preventDefault();
  const modal = document.querySelector('#chapterLists')
  modal.classList.remove('open-chapter')
  document.body.classList.remove('modalChapter-open')
}
